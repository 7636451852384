<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back" />
              </svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a
              href="javascript:void(0)"
              class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block"
              id="instrument"
              data-toggle="dropdown"
              aria-controls="instrument-contents"
              aria-expanded="false"
            >
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">
                01 - Transfer Caso
                <span class="badge badge-success">E-signed</span>
              </a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start">
            <div>
              <h2 class="page-title">A1 release</h2>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small>
                    <span class="text-muted text-small">Status:</span>
                    <strong>Drafted</strong>
                  </small>
                </li>
                <li class="list-inline-item">
                  <small>
                    <span class="text-muted">Modified:</span>
                    <strong>Sep 22, 2018 at 5:33 PM</strong>
                  </small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between ml-auto">
              <button
                class="btn btn-md btn-secondary d-md-none mb-2"
                type="button"
                data-toggle="collapse"
                data-target="#application-nav"
                aria-expanded="false"
                aria-controls="application-nav"
              >
                <svg class="icon icon-more">
                  <use xlink:href="/icons/symbol-defs.svg#icon-more" />
                </svg>
                <svg class="icon icon-close">
                  <use xlink:href="/icons/symbol-defs.svg#icon-close" />
                </svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none">
                  <use xlink:href="/icons/symbol-defs.svg#icon-delete" />
                </svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/questionnaire">Transferees</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Contact Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Transferors</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Property Description</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/part-e-terms">Terms</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Gross Purchase Price</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Additional Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Principal Residence Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Tax Calculation</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/first-time-home-buyer">First Time Home Buyers</router-link>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/certification">Certification</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachments</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Presentation Copy</a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9 col-lg-8">
              <h2 class="mb-3 pt-3">Certification</h2>
              <p
                class="lead"
              >Once the PTT form has been signed by all transferees, you can enter the date of signing below to match the signed copy.</p>

              <div class="alert alert-info">
                <div class="alert-icon">
                  <svg class="icon icon-info">
                    <use xlink:href="/icons/symbol-defs.svg#icon-info" />
                  </svg>
                </div>
                <strong>IMPORTANT – This portion of the return must be signed by all transferees.</strong>
                I certify and declare that the information given in this return is complete and correct in all respects. I acknowledge that there are penalties for tax avoidance or providing false information. These penalties may include double the tax, tax plus interest and a fine and/or up to two years imprisonment.
              </div>

              <div class="alert alert-primary bg-transparent py-0">
                <div class="alert-icon">
                  <svg class="icon icon-legal">
                    <use xlink:href="/icons/symbol-defs.svg#icon-legal" />
                  </svg>
                </div>
                <strong>Freedom of Information and Protection of Privacy Act (FOIPPA)</strong> – The personal information on this form is collected for the purpose of administering the Property Transfer Tax Act under the authority of section 26(a) of the FOIPPA. Questions about the collection of this information can be directed to the Director, Property Transfer Tax, PO Box 9427 Stn Prov Govt, Victoria, BC V8W 9V1 (telephone: Victoria at 250-387-0555 or toll-free at 1 888 355-2700)
              </div>

              <!-- Transferee 1 -->
              <div class="mb-4 p-2 p-md-4 bg-medium d-sm-flex justify-content-between">
                <h3>Jane Doe</h3>
                <div class="form-group col-sm-5 col-md-5 col-lg-4 mb-0">
                  <label for="date1">Date of Signing</label>
                  <div class="input-group">
                    <svg class="icon icon-inline-right icon-date position-absolute">
                      <use xlink:href="/icons/symbol-defs.svg#icon-date" />
                    </svg>
                    <input type="text" name="date1" id="date1" class="form-control" value="2019-06-14" />
                  </div>
                  <p class="form-text text-muted mb-0">YYYY-MM-DD</p>
                </div>
              </div>

              <div class="mb-4 p-2 p-md-4 bg-medium d-sm-flex justify-content-between">
                <h3>ABC Depot Company</h3>
                <div class="form-group col-sm-5 col-md-5 col-lg-4 mb-0">
                  <label for="date2">Date of Signing</label>
                  <div class="input-group">
                    <svg class="icon icon-inline-right icon-date position-absolute">
                      <use xlink:href="/icons/symbol-defs.svg#icon-date" />
                    </svg>
                    <input type="text" name="date2" id="date2" class="form-control" value="2019-06-14" />
                  </div>
                  <p class="form-text text-muted mb-0">YYYY-MM-DD</p>
                </div>
              </div>

              <div class="mb-4 p-2 p-md-4 bg-medium d-sm-flex justify-content-between">
                <h3>Beneficial NGO</h3>
                <div class="form-group col-sm-5 col-md-5 col-lg-4 mb-0">
                  <label for="date3">Date of Signing</label>
                  <div class="input-group">
                    <svg class="icon icon-inline-right icon-date position-absolute">
                      <use xlink:href="/icons/symbol-defs.svg#icon-date" />
                    </svg>
                    <input type="text" name="date3" id="date3" class="form-control" value="2019-06-14" />
                  </div>
                  <p class="form-text text-muted mb-0">YYYY-MM-DD</p>
                </div>
              </div>

              <p class="mb-4">
                <strong>CONSENT</strong> – I consent that the information provided on this return may be verified by accessing relevant records held by the Home Owner Grant Office, the Land Title and Survey Authority of British Columbia (LTSA), BC Assessment (BCA), the ministry responsible for the BC Provincial Nominee Program and other sources as required. Updated property information is provided to the LTSA, BCA and Canada Revenue Agency.
              </p>

              <!-- Action buttons -->
              <div
                class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none"
              >
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">
                    Continue
                    <svg class="icon icon-arrow_cta ml-1">
                      <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward">
          <use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward" />
        </svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>
import PackageHeader from "../../../components/PackageHeader.vue";
import PackageNavigation from "../../../components/PackageNavigation.vue";
import HelpPanel from "../../../components/HelpPanel.vue";
import DatePicker from "../../../components/DatePicker";

export default {
  components: {
    "package-header": PackageHeader,
    "package-navigation": PackageNavigation,
    "help-panel": HelpPanel
  },

  data() {
    return {};
  },
  methods: {}
};
</script>

